import * as React from "react"
import { graphql } from "gatsby"
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
// import useIntersectionObserver from "@react-hook/intersection-observer"

import Layout from "../components/layout"
import Seo from "../components/seo"
import MagazineDocument from "../components/magazine"

const MagazinePage = ({ data }) => {
  const { collections, title } = data.allMarkdownRemark.nodes[0].frontmatter

  return (
    <Layout title={title}>
      <Seo title={title} />
      {collections.map((collection, i) => (
        <MagazineDocument key={collection.title} {...collection} />
      ))}
    </Layout>
  )
}

export default MagazinePage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(magazine.md)/" } }
    ) {
      nodes {
        id
        html
        frontmatter {
          title
          collections {
            title
            file
            buy
            pages
          }
        }
      }
    }
  }
`
